export type Named = {
    name: string
}

export type StateNames = "strength" |
    "agily" |
    "physique" |
    "intelligence" |
    "wisdom" |
    "charisma"


export type State = {
    value: number
}

const MIN_STATE_VALUE = -2

const ALLOW_MIN_STATE_VALUE = -5

const MAX_STATE_VALUE = 5

const ALLOW_MAX_STATE_VALUE = 10

const DEFAULT_STATE_VALUE = MIN_STATE_VALUE


export type StateBasedRaceValue = {
    state: StateNames,
    value: number
}

export type AllStateRaceValue = {
    all: number
}

export type RaceValue = {
    value: StateBasedRaceValue | AllStateRaceValue
}

export type NamedRaceValue = Named & RaceValue

const RACES: { [key: string]: NamedRaceValue } = {
    people: {
        name: "Люди",
        value: {
            all: 1
        }
    },
    ogr: {
        name: "Огр",
        value: {
            state: "strength",
            value: 1,
        }
    }
}


const DEFAULT_STATES: { [key: string]: State & Named } = {
    strength: {
        name: "СИЛ",
        value: DEFAULT_STATE_VALUE
    },
    agily: {
        name: "ЛОВ",
        value: DEFAULT_STATE_VALUE
    },
    physique: {
        name: "ТЕЛ",
        value: DEFAULT_STATE_VALUE
    },
    intelligence: {
        name: "ИНТ",
        value: DEFAULT_STATE_VALUE
    },
    wisdom: {
        name: "МУД",
        value: DEFAULT_STATE_VALUE
    },
    charisma: {
        name: "ХАР",
        value: DEFAULT_STATE_VALUE
    },
}

export {
    DEFAULT_STATES,
    DEFAULT_STATE_VALUE,
    MIN_STATE_VALUE,
    MAX_STATE_VALUE,
    ALLOW_MAX_STATE_VALUE,
    ALLOW_MIN_STATE_VALUE,
    RACES
}
