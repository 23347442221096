import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from 'antd';
import {BrowserRouter} from 'react-router-dom';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ConfigProvider
        // theme={{
        //     // 1. Use dark algorithm
        //     algorithm: theme.darkAlgorithm,
        //
        //     // 2. Combine dark algorithm and compact algorithm
        //     // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        // }}
    >
        <BrowserRouter>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </BrowserRouter>
    </ConfigProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
