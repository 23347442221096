import React from 'react';
import {Flex, Layout, List, Select, Space, Typography} from 'antd';
import {
    ALLOW_MAX_STATE_VALUE,
    ALLOW_MIN_STATE_VALUE, AllStateRaceValue,
    DEFAULT_STATES,
    RACES
} from '../../utils';
import type {StateBasedRaceValue} from '../../utils'
import StateInput from "../StateInput";

const DEFAULT_MAX_POINTS = 15

export default function App() {
    const [selectedRace, setSelectedRace] = React.useState<string>()
    const [availablePoints, setAvailablePoints] = React.useState<number>(DEFAULT_MAX_POINTS)

    const options = React.useMemo(() => Object.keys(RACES)
        .map(key => {
            return {
                value: key,
                label: RACES[key].name,
            }
        }), [])

    const handleUpdate = (update: number) => {
        let updatedValue = availablePoints + update;
        if (updatedValue < 0) {
            return false
        }

        setAvailablePoints(updatedValue)
        return true
    }

    return (
        <>
            <Flex gap={"normal"} wrap>
                <Layout>
                    <Layout.Header style={{
                        // textAlign: 'center',
                        height: 64,
                        display: 'flex',
                        flexDirection: 'row',
                        // paddingInline: 48,
                        lineHeight: '64px',
                        backgroundColor: '#fff',
                    }}>
                        <Space>
                            <Typography.Title level={2}>
                                Раса
                            </Typography.Title>
                            <Select
                                defaultValue={selectedRace}
                                onChange={value => {
                                    setSelectedRace(value)
                                    setAvailablePoints(DEFAULT_MAX_POINTS)
                                }}
                                style={{width: 120}}
                                options={options}
                            />
                            <Typography.Title level={2}>
                                availablePoints: {availablePoints}
                            </Typography.Title>
                        </Space>
                    </Layout.Header>
                    <Layout.Content style={{}}>
                        <List
                            itemLayout={"vertical"}
                            bordered
                            dataSource={Object.keys(DEFAULT_STATES)}
                            renderItem={(key) => {
                                let initialValue = DEFAULT_STATES[key].value;

                                if (selectedRace) {
                                    const race = RACES[selectedRace];
                                    if ((race.value as AllStateRaceValue).all) {
                                        initialValue += (race.value as AllStateRaceValue).all
                                    } else {
                                        const castedType = race.value as StateBasedRaceValue;
                                        if (castedType.state === key) {
                                            initialValue += castedType.value
                                        }
                                    }

                                }

                                return (<List.Item>
                                    <StateInput
                                        name={DEFAULT_STATES[key].name}
                                        initialValue={initialValue}
                                        maxValue={ALLOW_MAX_STATE_VALUE}
                                        minValue={ALLOW_MIN_STATE_VALUE}
                                        onUp={() => handleUpdate(-1)}
                                        onDown={() => handleUpdate(+1)}
                                    />
                                </List.Item>)
                            }}
                        />
                    </Layout.Content>
                </Layout>
            </Flex>
        </>
    );
}
