import React from 'react'
import {InputNumber, Space, Typography} from "antd";

export type StateInputProps = {
    initialValue: number,
    minValue: number,
    maxValue: number,
    name: string,
    onDown: () => boolean,
    onUp: () => boolean,
}

export default function StateInput(props: StateInputProps) {
    const [value, setValue] = React.useState<number>(props.initialValue)
    React.useEffect(() => {
        setValue(props.initialValue)
    }, [props.initialValue])

    return (<>
        <Space>
            <Typography.Title level={4}>{props.name}</Typography.Title>
            <InputNumber
                value={value}
                min={props.minValue}
                max={props.maxValue}
                name={props.name}
                onChange={value => {
                    if (value === null) {
                        return
                    }

                    setValue((prevValue: number) => {
                        let updateAccepted = true
                        if (prevValue !== value) {
                            if (value > prevValue) {
                                updateAccepted = props.onUp()
                            } else {
                                updateAccepted = props.onDown()
                            }
                        }

                        return updateAccepted ? value : prevValue
                    })
                }}
            />
        </Space>
    </>)
}
